

const data = {
    // "AERONAVES": {
        "Cessna 172": {
            "MATRICULAS": {
                "DZF": {
                    "CG": 40.2,
                    "TIPO": "F172M",
                    "MOMENTO": 63.1,
                    "PESO": 1567.6
                },
                "EKJ": {
                    "CG": 36.4,
                    "TIPO": "172N",
                    "MOMENTO": 57.3,
                    "PESO": 1575.1
                },
                "FVP": {
                    "CG": 41.1,
                    "TIPO": "172N",
                    "MOMENTO": 66.9,
                    "PESO": 1630
                },
                "GNS": {
                    "CG": 39.3,
                    "TIPO": "172N",
                    "MOMENTO": 59.3,
                    "PESO": 1509.2
                },
                "HQQ": {
                    "CG": 43.9,
                    "TIPO": "F172",
                    "MOMENTO": 67.3,
                    "PESO": 1533.6
                },
                "HRE": {
                    "CG": 35.9,
                    "TIPO": "F172N",
                    "MOMENTO": 53.5,
                    "PESO": 1488.8
                },
                "HSM": {
                    "CG": 38,
                    "TIPO": "172N",
                    "MOMENTO": 59.2,
                    "PESO": 1558.7
                }

            },
            "MBDATA": {
                "fpax": 37,
                "rpax1": 73,
                "rpax2": 0,
                "bag1": 95,
                "bag2": 123,
                "bag3": 0,
                "bag4": 0,
                "oilna": 0,
                "fob": 48,
                "cruiseSpeed": 90
            }
        },
        "Tecnam P-Mentor": {
            "MATRICULAS": {
                "OAB": {
                    "CG": 0.297,
                    "TIPO": "PMENTOR",
                    "MOMENTO": 785.58,
                    "PESO": 452
                },
                "OAZ": {
                    "CG": 0.295,
                    "TIPO": "PMENTOR",
                    "MOMENTO": 782.94,
                    "PESO": 451
                },
                "OBR": {
                    "CG": 0.293,
                    "TIPO": "PMENTOR",
                    "MOMENTO": 785.5,
                    "PESO": 453
                },
                "OBT": {
                    "CG": 0.282,
                    "TIPO": "PMENTOR",
                    "MOMENTO": 777.07,
                    "PESO": 451
                }
            },
            "MBDATA": {
                "fpax": 1.804,
                "rpax1": 0,
                "rpax2": 0,
                "bag1": 2.26,
                "bag2": 0,
                "bag3": 0,
                "bag4": 0,
                "oilna": 0,
                "fob": 2.139,
                "cruiseSpeed": 100
            }
        },
        "PA28 Arrow": {
            "MATRICULAS": {
                "CUR": {
                    "CG": 85.1,
                    "TIPO": "PA-28R-200",
                    "MOMENTO": 148,
                    "PESO": 1739.4
                },
                "FEA": {
                    "CG": 84.7,
                    "TIPO": "PA-28R-200",
                    "MOMENTO": 146,
                    "PESO": 1722.8
                },
                "HLE": {
                    "CG": 83.4,
                    "TIPO": "PA-28R-200",
                    "MOMENTO": 136.8,
                    "PESO": 1640
                },
                "HNN": {
                    "CG": 85.4,
                    "TIPO": "PA-28R-180",
                    "MOMENTO": 134,
                    "PESO": 1568.8
                },
                "IDL": {
                    "CG": 87.9,
                    "TIPO": "PA-28R-200",
                    "MOMENTO": 145.6,
                    "PESO": 1656.7
                }
            },
            "MBDATA": {
                "fpax": 80.5,
                "rpax1": 118.1,
                "rpax2": 0,
                "bag1": 142.8,
                "bag2": 0,
                "bag3": 0,
                "bag4": 0,
                "oilna": 24.5,
                "fob": 95,
                "cruiseSpeed": 120
            }

        },
        "PA34 SENECA": {
            "MATRICULAS": {
                "HOZ": {
                    "CG": 87.6,
                    "TIPO": "PA-34-200T",
                    "MOMENTO": 254000,
                    "PESO": 2901.1
                },
                "FIF": {
                    "CG": 87.8,
                    "TIPO": "PA-34-220T",
                    "MOMENTO": 284125,
                    "PESO": 3234.5
                }
            },
            "MBDATA": {
                "fpax": 85.5,
                "rpax1": 118.1,
                "rpax2": 119.1,
                "rpax3": 157.6,
                "rpax4": 118.1,
                "bag1": 22.5,
                "bag2": 178.7,
                "bag3": 0,
                "bag4": 0,
                "oilna": 0,
                "fob": 93.6,
                "cruiseSpeed": 130
            }
        },
        "Tecnam P2006T": {
            "MATRICULAS": {
                "NEQ": {
                    "CG": 0.4,
                    "TIPO": "P2006T",
                    "MOMENTO": 367,
                    "PESO": 879
                },
                "OFT": {
                    "CG": 0.4,
                    "TIPO": "P2006T",
                    "MOMENTO": 364,
                    "PESO": 862
                }
            },
            "MBDATA": {
                "fpax": 37,
                "rpax1": 73,
                "rpax2": 0,
                "bag1": 95,
                "bag2": 123,
                "bag3": 0,
                "bag4": 0,
                "oilna": 0,
                "fob": 48,
                "cruiseSpeed": 110
            }

        },
        "Diamond 40": {
            "MATRICULAS": {
                "IQE": {
                    "CG": 2.4,
                    "TIPO": "DA40-180",
                    "MOMENTO": 1894.2,
                    "PESO": 788.6
                }
            },
            "MBDATA": {
                "fpax": 2.3,
                "rpax1": 3.25,
                "rpax2":  0,
                "bag1": 3.64,
                "bag2": 0,
                "bag3": 0,
                "bag4": 0,
                "oilna": 1,
                "fob": 2.62,
                "cruiseSpeed": 110
            }

        }
    }
// }

export default data;